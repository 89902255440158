import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
    path: '/', name: 'Index', component: () => import(/* webpackChunkName: "about" */ '../views/Index.vue'),
    redirect: "/list", children: [{
        path: '/list', name: 'List', component: () => import(/* webpackChunkName: "about" */ '../views/List.vue'),
    }, {
        path: '/detail', name: 'Detail', component: () => import(/* webpackChunkName: "about" */ '../views/Detail.vue'),
    }, {
        path: '/about', name: 'About', component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    }]
}]

const router = new VueRouter({
    mode: 'history', base: process.env.BASE_URL, routes
})
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location) {
    return originalPush.call(this, location).catch(() => {
    })
};
export default router
