import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        menuIconIndex: '/home',
        screenWidth: 0,
        screenHeight: 0,
    },
    mutations: {
        setMenuIconIndex(state, menuIconIndex) {
            state.menuIconIndex = menuIconIndex
        },
        setScreenWidth(state, screenWidth) {
            state.screenWidth = screenWidth
        },
        setScreenHeight(state, screenHeight) {
            state.screenHeight = screenHeight
        }
    },
    actions: {
        setMenuIconIndex(context, menuIconIndex) {
            context.commit('setMenuIconIndex', menuIconIndex)
        },
        setScreenWidth(context, screenWidth) {
            context.commit('setScreenWidth', screenWidth)
        },
        setScreenHeight(context, screenHeight) {
            context.commit('setScreenHeight', screenHeight)
        }
    },
    modules: {}
})
