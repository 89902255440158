<template>
    <div id="app" style="{
        position: relative;
    }">
        <router-view/>
        <div class="bodyToTop" @click="toTopAction">
            <i class="el-icon-caret-top"></i>
        </div>
    </div>
</template>
<script>

export default {
    name: "APP",
    data() {
        return {
            screenWidth: document.body.clientWidth, // 屏幕宽度
            screenHeight: document.body.clientHeight, // 屏幕高度
        }
    },
    created() {
        if (sessionStorage.getItem('store')) {
            this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem('store'))))
            sessionStorage.removeItem('store')
        }
        //在页面刷新时将vuex里的信息保存到sessionStorage里
        window.addEventListener('beforeunload', () => {
            sessionStorage.setItem('store', JSON.stringify(this.$store.state))
        })
    },
    mounted() {
        window.screenWidth = document.body.clientWidth;
        window.screenHeight = document.body.clientHeight;
        this.screenWidth = window.screenWidth;
        this.screenHeight = window.screenHeight;
        this.$store.dispatch("setScreenWidth", this.screenWidth);
        this.$store.dispatch("setScreenHeight", this.screenHeight);
        setTimeout(() => {
            window.screenWidth = document.body.clientWidth;
            window.screenHeight = document.body.clientHeight;
            this.screenWidth = window.screenWidth;
            this.screenHeight = window.screenHeight;
            this.$store.dispatch("setScreenWidth", this.screenWidth);
            this.$store.dispatch("setScreenHeight", this.screenHeight);
        }, 500)
    },
    methods: {
        toTopAction() {
            window.scrollTo(0, 0)
        }
    }
}
</script>
<style lang="scss">
@import "assets/iconfont.css";
@import "common/theme";
@import "common/common";

body {
    background-color: $body_bgColor;
}

.bodyToTop {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    background-color: white;
    position: fixed;
    right: 30px;
    bottom: 60px;
    z-index: 99999;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #3a8ee6;
    font-size: 18px;
}

.messageAlert {
    z-index: 3000 !important;
}
</style>
